<template>
  <div>
    <NavBarAdmin></NavBarAdmin>
    <div style="background: #F8F9FA; height: 100vh; overflow: scroll;">
      <div
        class="container"
        style="margin-top: 30px; background: white; border: 1px solid rgb(192, 192, 192);"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-2 mt-3">
              <h3 class="product-title ">Conductores</h3>
            </div>
            <div class="col-2 mt-3">
              <button
                type="button"
                class="btn btn-primary add-btn h-2"
                data-toggle="modal"
                data-target="#modalAddDriver"
              >
                <i class="fas fa-plus" style="margin-right: 10px;"></i> Agregar
              </button>
            </div>
            <div class="col-4 mt-3">
              <input
                type="text"
                v-model="buscarNombre"
                class="form-control"
                placeholder="Buscar por Nombre"
              />
            </div>
          </div>
        </div>
        <ul class="nav nav-pills nav-fill nav-position">
          <li class="nav-item second-nav" @click="redirectToDriver">
            <button
              style="background: transparent !important; border: 0 !important; color:white !important;"
              class="nav-link3"
            >
              Conductores
            </button>
          </li>
          <li class="nav-item third-nav" @click="redirectToRquest">
            <button
              style="background: transparent !important; border: 0 !important;"
              class="nav-link2"
            >
              Solicitud
            </button>
          </li>
        </ul>

        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <!-- <th class="header" scope="col">#</th> -->
              <th class="header" scope="col">Nombre</th>
              <th class="header" scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(driver, index) in drivers" :key="index">
              <!-- <th class="header" scope="row">1</th> -->
              <td class="content">
                {{ driver.get("fullName") }}
                <span
                  class="online-dot"
                  v-if="
                    driver.get('isWorking') === true ||
                      driver.get('isActive') === true
                  "
                ></span>
              </td>
              <td class="content">
                <button
                  @click="viewInfo(driver)"
                  type="button"
                  class="btn btn-info mr-2"
                >
                  Ver
                </button>
                <button
                  v-if="driver.get('isActivate') === true"
                  @click="turnOff(driver)"
                  type="button"
                  class="btn btn-danger mr-2"
                >
                  Desactivar
                </button>
                <button
                  v-if="driver.get('isActivate') != true"
                  @click="turnOn(driver)"
                  type="button"
                  class="btn btn-success mr-2"
                >
                  Activar
                </button>
                <button
                  v-if="false"
                  @click="driverReviews(driver)"
                  type="button"
                  class="reviews-btn mr-2"
                >
                  Reviews
                </button>
                <button
                  @click="myFunction(driver)"
                  type="button"
                  class="btn btn-warning mr-2"
                >
                  Borrar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- modal driver -->

      <div
        class="modal fade"
        id="modalAddDriver"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalAddDriver"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLongTitle">
                Agregar Conductor
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label>Nombre:</label>
                  <input
                    type="text"
                    v-model="Name"
                    class="form-control"
                    id="nombre"
                  />
                </div>
                <div class="form-group">
                  <label>Telefono:</label>
                  <input
                    type="tel"
                    v-model="Phone"
                    class="form-control"
                    id="telefono"
                  />
                </div>
                <div class="form-group">
                  <label>Correo Electronico:</label>
                  <input
                    type="email"
                    v-model="Email"
                    class="form-control"
                    id="correo"
                  />
                </div>
                <div class="form-group">
                  <label>Descripcion:</label>
                  <textarea
                    class="form-control"
                    v-model="Description"
                    id="descripcion"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label>Farmacia:</label>
                  <select v-model="Farmacia" class="form-control" id="rol">
                    <option>Farmacia</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <input
                type="button"
                class="btn btn-primary btn-lg btn-block"
                value="Procesar Conductor"
                id="btnGuardar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBarAdmin from "../../components/NavBarAdmin.vue";
import Parse from "parse";
export default {
  name: "Drivers",
  components: { NavBarAdmin },
  data() {
    return {
      drivers: null,
      Name: "",
      Phone: "",
      Email: "",
      Description: "",
      Farmacia: "",
      superAdmin: false,
      admin: false,
      buscarNombre: ""
    };
  },
  watch: {
    buscarNombre() {
      if (this.buscarNombre !== "") {
        this.buscarporNombre();
      } else {
        this.getDriversInfo();
      }
    }
  },
  mounted() {
    this.superAdmin = Parse.User.current().get("superAdmin");
    this.admin = Parse.User.current().get("isAdmin");
    if (this.admin && !this.superAdmin) {
      this.$router.push("/userTabs");
    }
    if (Parse.User.current() === null) {
      this.$router.push("/HelloWorld");
    }
    if (!this.admin && !this.superAdmin) {
      this.$router.push("/");
    }
    let client = new Parse.LiveQueryClient({
      applicationId: "C0XMchZu6Y9XWNUK4lM1UHnnuXhC3dcdpa5fGYpO",
      serverURL: "wss://" + "coopharma.back4app.io", // Example: 'wss://livequerytutorial.back4app.io'
      javascriptKey: "EdN4Xnln11to6pfyNaQ5HD05laenoYu04txYAcfo",
      masterKey: "4ZCJxnFPx9nTK4SWDwBq4imO8MOj8e01L9KoDyyO"
    });
    client.open();
    let query = new Parse.Query("_User");
    query.equalTo("role", "E");
    query.equalTo("isApproved", true);
    let subscription = client.subscribe(query);
    subscription.on("create", () => {
      this.getDriversInfo();
    });
    subscription.on("update", () => {
      this.getDriversInfo();
    });
    subscription.on("delete", () => {
      this.getDriversInfo();
    });
    this.getDriversInfo();
  },
  methods: {
    buscarporNombre() {
      const query = new Parse.Query("_User");
      query.equalTo("role", "E");
      query.notEqualTo("flag", "H");
      query.equalTo("isApproved", true);
      query.fullText("fullName", this.buscarNombre);
      query.find().then(result => {
        this.drivers = result;
      });
    },
    redirectToDriver() {
      this.$router.push({
        name: "adminDrivers"
      });
    },
    redirectToRquest() {
      this.$router.push({
        name: "acceptDrivers"
      });
    },
    getDriversInfo() {
      const query = new Parse.Query("_User");
      query.equalTo("role", "E");
      query.notEqualTo("flag", "H");
      query.equalTo("isApproved", true);
      query.find().then(result => {
        this.drivers = result;
      });
    },
    viewInfo(data) {
      this.$router.push("/driversProfile/" + data.id);
    },
    deleteDriver(driver) {
      Parse.Cloud.run("deleteDriver", {
        objectId: driver.id
      }).then(() => {
        this.getDriversInfo();
      });
    },
    turnOn(data) {
      Parse.Cloud.run("changeStatusActivate", {
        userId: data.id,
        status: true
      }).then(() => {
        this.getDriversInfo();
      });
    },
    turnOff(data) {
      Parse.Cloud.run("changeStatusActivate", {
        userId: data.id,
        status: false
      }).then(() => {
        this.getDriversInfo();
      });
    },
    driverReviews(driver) {
      this.$router.push({
        path: "/reviews",
        query: {
          driverId: driver.id
        }
      });
    },
    myFunction(driver) {
      let txt;
      if (confirm("¿Estás seguro que quieres borrar este conductor?")) {
        txt = "You pressed OK!";
        this.deleteDriver(driver);
      } else {
        txt = "You pressed Cancel!";
      }
      console.log(txt);
    }
  }
};
</script>
<style scoped>
.navbar,
.navbar-expand-lg,
.navbar-light,
.bg-light {
  background: #e0e0e0 !important;
}

.nav-item {
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  color: rgba(0, 0, 0) !important;
  font-weight: 200;
  font-size: 13px;
  /* letter-spacing: 1px; */
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #0e9390 !important;
  margin-left: 20px;
  margin-right: 20px;
}

.logo-link {
  position: absolute;
  left: 20px;
  top: 10px;
}

.logo {
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin-right: 30px;
}

.navbar-toggler {
  border: 2px solid rgb(184, 184, 184) !important;
}

.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.nav-link2 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
}

.nav-position {
  padding-top: 30px;
}

.second-nav {
  background: #0e9390;
  height: 50px;
  padding-top: 13px;
}

.third-nav {
  background: rgb(44, 44, 44);
  height: 50px;
  padding-top: 13px;
}

.second-nav:hover {
  background: #0a6b69;
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.third-nav:hover {
  background: rgba(44, 44, 44, 0.692);
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}

.content {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  text-align: center;
  padding-top: 10px;
}

.delete-btn {
  border-radius: 5px;
  margin: 5px;
  background: #07de51;
  height: 35px;
  border: none;
  color: white;
  font-weight: 600;
  width: 100px;
}

.reviews-btn {
  border-radius: 5px;
  margin: 5px;
  background: #097b83;
  height: 35px;
  border: none;
  color: white;
  font-weight: 600;
  width: 100px;
}

.reviews-btn2 {
  border-radius: 5px;
  margin: 5px;
  background: rgb(253, 24, 24);
  height: 35px;
  border: none;
  color: white;
  font-weight: 600;
  width: 100px;
}

.see-btn {
  border-radius: 5px;
  margin: 5px;
  background: rgb(236, 229, 229);
  height: 35px;
  border: none;
  color: black;
  font-weight: 600;
  width: 100px;
}

.online-dot {
  height: 10px;
  width: 10px;
  background-color: #00cc00;
  border-radius: 50%;
  display: inline-block;
}
</style>
